import React, { useContext } from 'react';
import { RedocStandalone } from 'redoc';
import { AppDataContext } from '../../../App';

const ApiDocs = () => {
    const appContext = useContext(AppDataContext);
    appContext.setSelectedTab(5);
    return (
        <div className='overflow-y-auto h-[90vh]'>
        <RedocStandalone
            specUrl="/openapi-spec.yaml"

        />
        </div>
    );
};

export default ApiDocs;
