import "@vonage/vwc-icon";
import { useContext, useState, useEffect, useCallback } from "react";
import { AppDataContext } from "../../../App.jsx";
import { getAllAlerts } from "../../../service/Endpoints.js";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { AlertDetailsRespose } from "../../../dto/ResponeDTO.ts";
import Loader from "../../utils/Loader.jsx";
import EditAlert from "../common/EditAlert.jsx";
import assignedIcon from "../../../assets/assigned_icon.png";
import { Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import DehazeIcon from "@mui/icons-material/Dehaze";

export default function UserData() {
  //State---------------------------------------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState();
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState();
  const [totalRecordsNew, setTotalRecordsNew] = useState(0);
  const [totalRecordsAssg, setTotalRecordsAssg] = useState(0);
  const [userAlerts, setUserAlertsAlerts] = useState(new AlertDetailsRespose());
  const [userNotAlerts, setUserNotAlertsAlerts] = useState(new AlertDetailsRespose());
  const [togleDataChange, setTogleDataChange] =useState(0)
  //---------------------------------------------------------------------------------State
  
  //API Calls---------------------------------------------------------------------------------

  const getStatsFromApi = () => {
    let pageable = new PagableFilter();
    let token = authState.accessToken.accessToken;
    pageable.limit = 1;
    pageable.offset = 0;
    pageable.addFilter("status", "NEW");
    callApi(
      { url: getAllAlerts(pageable) },
      (responseData) => {
        const response = new AlertDetailsRespose();
        response.applyData(responseData);
        setTotalRecordsNew(response.totalRecords);
      },
      token
    );
    pageable = new PagableFilter();
    pageable.limit = 1;
    pageable.offset = 0;
    pageable.addFilter("status", "ASSIGNED");
    callApi(
      { url: getAllAlerts(pageable) },
      (responseData) => {
        const response = new AlertDetailsRespose();
        response.applyData(responseData);
        setTotalRecordsAssg(response.totalRecords);
      },
      token
    );
    setLoading(false);
  };
  const getAlertsFromAPI = useCallback((pageable, setterMethod) => {
    setLoading(true);
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAllAlerts(pageable) },
      (responseData) => {
        const response = new AlertDetailsRespose();
        response.applyData(responseData);
        setterMethod(response);
      },
      token
    );
    setLoading(false);
  });
  //---------------------------------------------------------------------------------API Calls

  //hooks
  const { authState } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();

  //context
  const appContext = useContext(AppDataContext);
  //SideEffects---------------------------------------------------------------------------------
  useEffect(() => {
    setLoading(true);
    setUserName(appContext.userData.name);
    let pageable = new PagableFilter();
    pageable.limit = 15;
    pageable.offset = 0;
    pageable.addFilter("userId", appContext.userData.userId);
    pageable.addFilter("verdict", "NOT DETERMINED");
    getAlertsFromAPI(pageable, setUserAlertsAlerts);
    pageable = new PagableFilter();
    pageable.limit = 15;
    pageable.offset = 0;
    pageable.addFilter("userId", appContext.userData.userId, "not");
    pageable.addFilter("verdict", "NOT DETERMINED");
    getAlertsFromAPI(pageable, setUserNotAlertsAlerts);
    getStatsFromApi();
  }, [togleDataChange]);
  //---------------------------------------------------------------------------------SideEffects

  //functions

  const onDataChangeHandler = () => {
    setTogleDataChange(prev=>prev==0?1:0)
  };

  const onAlertDetailBtnHandler = (alertId) => {
    setShowEditAlert(true);
    setSelectedAlertId(alertId);
  };

  const onCloseEditAlertHandler = () => {
    setShowEditAlert(false);
  };

  return (
    <>
      <div className="w-full rounded-xl p-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="relative flex w-full flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md mt-6">
              <div className="relative mx-4 -mt-10 h-30 overflow-auto rounded-xl  bg-clip-border text-white shadow-lg shadow-fuchsia-gray-500/40 bg-gradient-to-r from-fuchsia-300 to-violet-200">
                <h2 className="mx-7 mt-5">
                  <span className="font-base">{"Hello "}</span>
                  <span className="font-black">{userName}</span>
                  <span className="font-base">{". "}</span>
                  <span className="font-base">{"Welcome To "}</span>
                  <span className="font-black">FRED</span>
                </h2>
                <div className="flex flex-row justify-center mb-1">
                  <div class="hover:-translate-y-2 group bg-neutral-50 duration-500  mt-2 w-12 h-12 flex text-neutral-600 flex-col justify-center items-center relative rounded-xl overflow-hidden shadow-md">
                    <svg
                      viewBox="0 0 200 200"
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute blur z-10 fill-fuchsia-300 duration-500 group-hover:blur-none group-hover:scale-105"
                    >
                    </svg>

                    <div class="z-20 flex flex-col justify-center items-center">
                      <span class="font-bold text-xs">
                        {userAlerts.totalRecords}
                      </span>
                      <p class="font-semibold text-[10px]">My Task</p>
                    </div>
                  </div>
                  <div class="hover:-translate-y-2 group bg-neutral-50 duration-500 ml-10 mt-2 w-12 h-12 flex text-neutral-600 flex-col justify-center items-center relative rounded-xl overflow-hidden shadow-md">
                    <svg
                      viewBox="0 0 200 200"
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute blur z-10 fill-violet-300 duration-500 group-hover:blur-none group-hover:scale-105"
                    >
                    </svg>

                    <div class="z-20 flex flex-col justify-center items-center">
                      <span class="font-bold text-xs">{totalRecordsAssg}</span>
                      <p class="font-semibold text-[10px]">Assigned</p>
                    </div>
                  </div>
                  <div class="hover:-translate-y-2 group bg-neutral-50 duration-500 ml-10 mt-2 w-12 h-12 flex text-neutral-600 flex-col justify-center items-center relative rounded-xl overflow-hidden shadow-md">
                    <svg
                      viewBox="0 0 200 200"
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute blur z-10 fill-purple-300 duration-500 group-hover:blur-none group-hover:scale-105"
                    >
                    </svg>

                    <div class="z-20 flex flex-col justify-center items-center">
                      <span class="font-bold text-xs">{totalRecordsNew}</span>
                      <p class="font-semibold text-[10px]">New</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-6 h-full">
                <div class="font-sans text-sm font-semibold tracking-normal text-blue-gray-900 antialiased justify-between flex">
                  Assigned To Me
                </div>

                <div className="no-scrollbar shadow shadow-fuchsia-200 divide-y divide-slate-300 mt-2 h-[25vh] rounded-xl overflow-x-auto  max-h-[700px] bg-opacity-80 border-[1px]">
                  {userAlerts.alerts.map((alert, idx) => {
                    return (
                      <>
                        <div
                          className="flex flex-row m-2 justify-start py-2  alert-card-entry-animation hover:scale-[0.97] cursor-pointer active:bg-violet-200 hover:bg-purple-50 hover:rounded-xl"
                          key={idx}
                          onClick={() =>
                            onAlertDetailBtnHandler(alert.alertId)
                          }
                        >
                          <div className="flex flex-col">
                            <div className="w-90 text-xs font-base text-blue-600 ml-2 my-auto tracking-tighter">
                              {alert.alertId}
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <div className="max-w-fit text-[10px]  font-normal text-gray-600 ml-2 my-auto bg-gray-100 shadow-2xl rounded-2xl pl-2 pr-2 tracking-tighter">
                                {alert.alertType}
                              </div>
                              <div className="w-60 text-red-400 text-[12px] tracking-tighter">
                                Assigned On: {alert.assignedOn}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <Link to={`/my-alerts`}>
                    <Tooltip title="More">
                      <Button>
                        <DehazeIcon />
                      </Button>
                    </Tooltip>
                  </Link>
                </div>
                <div class="font-sans text-sm font-semibold tracking-normal text-blue-gray-900 antialiased justify-between flex mt-4">
                  Assigned To Others
                </div>

                <div className="no-scrollbar shadow shadow-fuchsia-200 divide-y divide-slate-300 mt-2 h-[25vh] rounded-xl overflow-x-auto  bg-opacity-80 border-[1px]">
                  {userNotAlerts.alerts.map((alert, idx) => {
                    return (
                      <>
                        <div
                          className="flex flex-row m-2  justify-start py-2 alert-card-entry-animation hover:scale-[0.97] cursor-pointer active:bg-violet-200 hover:bg-purple-50 hover:rounded-xl"
                          key={idx}
                          onClick={() =>
                            onAlertDetailBtnHandler(alert.alertId)
                          }
                        >
                          <div className="flex flex-col">
                            <div className="w-90 text-xs font-base text-blue-600 ml-2 my-auto tracking-tighter">
                              {alert.alertId}
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <div className="max-w-fit text-[10px] font-normal text-gray-600 ml-2 my-auto bg-gray-100 shadow-2xl rounded-2xl pl-2 pr-2 tracking-tighter">
                                {alert.alertType}
                              </div>
                              <div className="w-60 text-gray-400 font-semibold text-[10px] tracking-tighter flex flex-row">
                                <img
                                  src={assignedIcon}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: 1,
                                  }}
                                />
                                {alert.assignedTo}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <Link to={`/all-alerts`}>
                    <Tooltip title="More">
                      <Button>
                        <DehazeIcon />
                      </Button>
                    </Tooltip>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <EditAlert
        showEditAlert={showEditAlert}
        onCloseEditAlertHandler={onCloseEditAlertHandler}
        alertId={selectedAlertId}
        dataChangeHandler={onDataChangeHandler}
      />
    </>
  );
}
