import * as React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { YearlyStatsResponse } from "../../../dto/ResponeDTO.ts";
import useHttp from "../../../hooks/useHttp.js";
import { useOktaAuth } from "@okta/okta-react";
import { getYearlyStats } from "../../../service/Endpoints.js";

const stylePagination = {
  container: "flex flex-row h-4 justify-center mb-5 z-100",
  btn: "w-10  z-100 mt-2  shadow-sm shadow-fuchsia-200 rounded-lg text-fuchsia-400 text-sm transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-fuchsia-300",
};

export default function LineStat() {
  //State---------------------------------------------------------------------------------
  const [stats, setStats] = React.useState(new YearlyStatsResponse().yearlyStats);
  //---------------------------------------------------------------------------------State

  //hooks---------------------------------------------------------------------------------
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  //---------------------------------------------------------------------------------hooks

  //API Calls---------------------------------------------------------------------------------
  const getYearlyStatsFromAPI = React.useCallback((year) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getYearlyStats(year) },
      (responseData) => {
        const response = new YearlyStatsResponse();
        response.applyData(responseData);
        setStats(response.yearlyStats);
      },
      token
    );
  });
  //---------------------------------------------------------------------------------API Calls

  //SideEffects---------------------------------------------------------------------------------
  React.useEffect(() => {
    getYearlyStatsFromAPI(new Date().getFullYear());
  }, []);

  //---------------------------------------------------------------------------------SideEffects
  const lineRef = React.useRef(null);

  React.useEffect(() => {
    const svgLines = document.querySelectorAll("path.recharts-curve");
    svgLines.forEach((line, index) => {
      const totalLength = line.getTotalLength();
      line.style.strokeDasharray = totalLength;
      line.style.strokeDashoffset = totalLength;

      // Trigger the animation
      setTimeout(() => {
        line.style.transition = `stroke-dashoffset 2s ease-in-out ${index * 0.5}s`;
        line.style.strokeDashoffset = "0";
      }, 100);
    });
  }, [stats]);
  return (
    <div className="rounded-2xl relative  m-auto  rounded-xl">



      <ResponsiveContainer
        width="85%"
        height={240}
        className="rounded-2xl mt-[1px] mx-auto"
      >
        <LineChart
          data={stats}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
          
        >
          <CartesianGrid/>
          <Tooltip />
          <Legend />
          <XAxis
            dataKey="month"
            tick={{ fontSize: 11, fill: "#bcbec2" }}
            interval="preserveStartEnd"
            axisLine={{ stroke: "#dee0e3" }}
          />
          <Line
            type="monotone"
            dataKey="fraud"
            stroke="rgba(255, 0, 0, 0.5)"
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(255, 0, 0, 0.8))",
            }}
          />
          <Line
            type="monotone"
            dataKey="notfraud"
            stroke="rgba(0, 200, 0, 0.5)"
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            strokeWidth={2}
            
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(0, 200, 0, 0.8))",
            }}
          />
          <Line
            type="monotone"
            dataKey="total"
            stroke="rgba(138, 43, 226, 0.3)"
            isAnimationActive={false}
            strokeWidth={2}
            activeDot={false}
            dot={false}
            // activeDot={{ r: 8 }}
            // dot={{ r: 1, fill: "#FFFFFF", stroke: "#8A2BE2", strokeWidth: 2 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(138, 43, 226, 0.8))",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <div id="pagination" className={stylePagination.container}>
        <div id="filter-prev-btn">
          <button className={stylePagination.btn+" z-10"} onClick={() => {}}>
            <vwc-icon type="double-chevron-left-solid" size="medium" />
          </button>
        </div>
        <div className="mx-10 text-sm text-purple-400">{"2024"}</div>
        <div id="filter-next-btn">
          <button className={stylePagination.btn} onClick={() => {}}>
            <vwc-icon type="double-chevron-right-solid" size="medium" />
          </button>
        </div>
      </div>
    </div>
  );
}
