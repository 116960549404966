import "@vonage/vwc-icon";
import * as React from "react";
import { useContext } from "react";
import { AppDataContext } from "../../../App.jsx";
import DataViewer from "../common/ActiveAlertViewer.jsx";
import { FieldFilter } from "../../../dto/ApplicationDTO.ts";

export default function AllAlert() {

  //context---------------------------------------------------------------------------------
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(1);
  //---------------------------------------------------------------------------------context

  return (
    <DataViewer key={"all-alert"}
    fixedFilter={[new FieldFilter("verdict","NOT DETERMINED")]} notFilter={[4]}
    />
  );
}
