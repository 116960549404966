import {useCallback, useState } from "react";
export default function useHttp() {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const sendRequest = useCallback(async (requestConfig, applyData, authentication) => {
        setIsLoading(true)
        setError(null)
        let headerHolder={};
        if(authentication)
        {

            headerHolder={...headerHolder,'Authorization':"Bearer "+ authentication}
        }
        if(requestConfig.headers)
        {
            headerHolder={...headerHolder, ...requestConfig.headers} 
        }
        try {
            const response = await fetch(requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: headerHolder,
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null
            });
            if(response.status===401)
                throw new Error("Unauthorized Access.")
            if (!response.ok) {
                const data = await response.json();
                if(data['message'])
                    throw new Error(data['message'])
                else
                    throw new Error('Request failed due to:'+data)
            }
            const data = await response.json();
            applyData(data)
        }
        catch (err) {
            setError(err.message || "Service Not available")
        }
        setIsLoading(false);
    }, [])
    return {
        isLoading,
        error,
        sendRequest
    }
}