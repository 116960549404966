import { useContext} from "react";
import { AppDataContext } from "../../../App.jsx";
import * as React from "react";
import DataViewer from "../common/ActiveAlertViewer.jsx";

export default function History() {
  //Context---------------------------------------------------------------------------------
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(3);
  //---------------------------------------------------------------------------------Context

  return (
    <DataViewer type={"history"} notFilter={[2,3]}
    />
  );
}
