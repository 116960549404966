import { useState,createContext } from "react";


export const OverlayContext = createContext({
  setShowOverlay:()=>{},
  setOverlayContent:()=>{}
});

export default function Overlay(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayContent, setOverlayContent] = useState(<></>);
  
  return (
    <>
     <OverlayContext.Provider value={{setShowOverlay:setShowOverlay,setOverlayContent:setOverlayContent}}>
      {showOverlay && (
        <>
        
        {overlayContent}
        </>
      )}
      {props.children}
       </OverlayContext.Provider>
    </>
  );
}
