import './404.css'
import astronut from '../../../assets/astronaut.svg'
import NotFound404 from '../../../assets/404.png'
import vonageLogo from "../../../assets/VonageLogo_Secondary.png"
export default function NotFound(){


    return<>
    <div class="bg-purple">
        
        <div class="stars">
            <div class="central-body">
                <img class="image-404" src={NotFound404} width="300px"/>
                <p className="text-xl font-lg">Not that we are bearer of bad news but...Something went wrong</p>
            </div>
            <img
            src={vonageLogo}
            style={{
              width: 250,
              height: 200,
              margin: "auto",
              position: "absolute",
              left:0,
              right:0,
              top:0,
              bottom:0
            }}
          />
            <div class="objects">
                <div class="box_astronaut">
                    <img class="object_astronaut" src={astronut} width="140px"/>
                </div>
            </div>
        </div>

    </div>
    </>
}