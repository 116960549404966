import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import _ from "lodash";
import { AppDataContext } from "../../../App";
import { useOktaAuth } from "@okta/okta-react";
import { UrlScanResultResponse } from "../../../dto/ResponeDTO.ts";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import useHttp from "../../../hooks/useHttp.js";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { exportUrlScanData, getUrlLiveScan, getUrlScannedData } from "../../../service/Endpoints.js";
import { Pagination } from "@mui/material";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GetAppIcon from '@mui/icons-material/GetApp';


const UrlScanner = () => {
  /*Constants---------------------------------------------------------------------------------*/
  const sortField = "recievedOn";
  const sortTypes = ["Oldest", "Newest"];
  const pageLimit = 20;
  /*---------------------------------------------------------------------------------Constants*/

  /*Context---------------------------------------------------------------------------------*/
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(4);
  const { showNotification } = useNotification();
  const pageLoaderContext = useContext(PageLoaderContext);
  /*---------------------------------------------------------------------------------Context*/

  /*States---------------------------------------------------------------------------------*/
  const [events, setEvents] = useState([]);
  const [scanInProgress, setScanInProgress] = useState(false);
  const [scanResults, setScanResults] = useState(new UrlScanResultResponse());
  const [urlList, setUrlList] = useState([]);
  const [singleUrl, setSingleUrl] = useState("");
  const [paginationFilter, setPaginationFilter] = useState(new PagableFilter());
  const [viewCurrentScanned, setVieCurrentScanned] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [filterUrl, setFilterUrl] = useState("");
  /*---------------------------------------------------------------------------------States*/

  /*Hooks---------------------------------------------------------------------------------*/
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  /*---------------------------------------------------------------------------------Hooks*/

  /*CallApi---------------------------------------------------------------------------------*/

  const getUrlScannedDataFromAPI = React.useCallback((pagableFilter) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getUrlScannedData(pagableFilter) },
      (response) => {
        const responseBody = new UrlScanResultResponse();
        responseBody.applyData(response);
        setScanResults(responseBody);
        setTotalPage(Math.ceil(responseBody.totalRecords / pageLimit));
      },
      token
    );
  });


  const exportData = async () => {
    pageLoaderContext.showLoader(true)
    try {
      const response = await fetch(exportUrlScanData(), {
        method: "GET",
        headers: {'Authorization':"Bearer "+ authState.accessToken.accessToken},
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", generateFileName());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the CSV file:", error);
    }
    pageLoaderContext.showLoader(false)
  };
  /*---------------------------------------------------------------------------------CallApi*/

  /*SideEffects---------------------------------------------------------------------------------*/
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!viewCurrentScanned) {
      let pageable = new PagableFilter();
      pageable.limit = pageLimit;
      pageable.offset = 0;
      setPaginationFilter(pageable);
      setCurrentPage(1);
    }
  }, [viewCurrentScanned]);
  useEffect(() => {
    if (paginationFilter.limit > -1 && paginationFilter.offset > -1)
      getUrlScannedDataFromAPI(paginationFilter);
  }, [paginationFilter]);
  /*---------------------------------------------------------------------------------SideEffects*/

  /*Functions---------------------------------------------------------------------------------*/
  const generateFileName = () => {
    const now = new Date();

    // Format the date and time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Combine all parts to create a unique file name
    return `URL_Scanned_${year}${month}${day}_${hours}${minutes}${seconds}.csv`;
  };

  const onScanDone = () => {
    setUrlList([]);
    setVieCurrentScanned(false);
  };
  const onSearchHandler = () => {
    const pagableFilter = new PagableFilter();
    if (filterUrl) {
      pagableFilter.addFilter("url", filterUrl, "contains");
    }
    pagableFilter.limit = pageLimit;
    pagableFilter.offset = 0;
    setCurrentPage(1);
    setPaginationFilter(pagableFilter);
  };

  const onPaginationChangeHandler = (page) => {
    setPaginationFilter((prev) => {
      let pageable = new PagableFilter();
      setCurrentPage(page);
      pageable.filters = prev.filters;
      pageable.limit = pageLimit;
      pageable.offset = page * pageLimit - pageLimit;
      return pageable;
    });
  };

  const addUrlForScan = () => {
    if (singleUrl) {
      setUrlList((prev) => [singleUrl, ...prev]);
      setSingleUrl("");
    }
  };
  const onStartScan = () => {
    setScanInProgress(true);
    const requestBody = {
      urls: urlList,
    };

    fetch(getUrlLiveScan(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authState.accessToken.accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          setEvents((prev) => [...prev, { type: "Failed to scan" }]);
        }
        return response.body;
      })
      .then((body) => {
        const reader = body.getReader();
        const decoder = new TextDecoder("utf-8");
        let eventSourceData = "";

        const readStream = () => {
          reader.read().then(({ done, value }) => {
            if (done) return;
            eventSourceData += decoder.decode(value, { stream: true });
            const events = eventSourceData.split("\n\n");
            events.forEach((eventString) => {
              if (!eventString.trim()) return;
              const lines = eventString.split("\n");
              const eventType = lines[0].replace("event:", "").trim();
              if (eventType == "Scan Results" && lines[1].startsWith("data")) {
                const temp = lines[1].replace("data:", "").trim();
                const result = new UrlScanResultResponse();
                result.applyData(JSON.parse(temp));
                setScanResults(result);
                setEvents([]);
                setScanInProgress(false);
                setVieCurrentScanned(true);
              }

              setEvents((prev) => [...prev, { type: eventType }]);
            });

            eventSourceData = events[events.length - 1]; // Save any incomplete data for the next chunk
            readStream();
          });
        };

        readStream();
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.name.split(".").pop().toLowerCase();

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result;

      if (fileType === "csv") {
        Papa.parse(fileData, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const data = results.data;
            const urls = data.map((row) => row[columnName]).filter(Boolean);
            if (urls.length > 100) {
              showNotification("Maximum 100 urls can be uploaded", "error");
            } else setUrlList(urls);
          },
        });
      } else if (fileType === "xls" || fileType === "xlsx") {
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const urls = sheetData.map((row) => row[columnName]).filter(Boolean);
        if (urls.length > 100) {
          showNotification(
            "Maximum 100 urls can be uploaded" + urls.length,
            "error"
          );
        } else setUrlList(urls);
      } else {
        alert("Unsupported file format. Please upload a CSV or Excel file.");
      }
    };

    if (fileType === "csv") {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };
  /*---------------------------------------------------------------------------------Functions*/

  const [columnName, setColumnName] = useState("URLs/domains/emails");

  return (
    <div className="flex flex-row gap-5">
      <div className="w-[30vw]  bg-white rounded-lg shadow-md h-[80vh] ">
        <div className="flex flex-row m-auto w-fit mt-5">
          <input
            type="text"
            id="name"
            value={singleUrl}
            onChange={(event) => setSingleUrl(event.target.value)}
            placeholder="Enter your url......"
            className="mt-1 block w-[20vw] bg-white border-[1px] rounded-md border-gray-300 shadow-inner focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 h-[3vh] text-[0.6vw] p-1"
          />
          <button
            onClick={addUrlForScan}
            className="border-[1px] text-[0.7vw] h-[3vh] m-auto px-4 rounded-[10px] ml-2 hover:shadow-2xl duration-200 shadow-sm active:bg-blue-200"
          >
            <AddLinkIcon/>
            Add Url
          </button>
        </div>
        <div className="text-[0.8vw] font-semibold mb-1 text-center mt-5">
          Or
        </div>
        <div className="p-2">
          <div className="mb-2 text-center">
            <span className="mr-2 text-[0.7vw]">Upload File:</span>
            <input
              type="file"
              accept=".csv, .xls, .xlsx"
              onChange={handleFileUpload}
              className="mb-4 text-[0.7vw]"
            />
          </div>
          <div className="text-[0.7vw] font-bold text-gray-500 mb-1">
            URLs for scan:
          </div>
          <div className="h-[50vh] overflow-y-scroll no-scrollbar shadow-inner bg-gray-50/20 border-[1px] border-gray-200 rounded-[5px]">
            {urlList.length > 0 ? (
              <ul className="list-disc pl-5 ">
                {urlList.map((url, index) => (
                  <li
                    className="border-[1px] border-white border-b-gray-300 text-[0.6vw] p-2 w-[25vw] text-blue-700"
                    key={index}
                  >
                    {url}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="m-auto text-gray-400 text-center mt-10">
                No URLs Added.
              </div>
            )}
          </div>
        </div>
        {urlList.length > 0 && (
          <div className="text-center">
            <button
              className="border-[1px] text-[0.7vw] h-[3vh] m-auto px-4 rounded-[10px] ml-2 hover:shadow-2xl duration-200 shadow-sm active:bg-blue-200 m-auto"
              onClick={onStartScan}
            ><DocumentScannerIcon/>
              Start Scan
            </button>
          </div>
        )}
      </div>
      <div className="p-4 bg-white rounded-lg shadow-md w-[60vw]">
        {scanInProgress ? (
          <div>
            <h1 className="text-red-500">
              Scan In Progress
              <span className="loading loading-spinner loading-sm pt-1"></span>
            </h1>
            <ul className="bg-gray-800 h-[60vh] overflow-y-auto rounded-lg p-1">
              {events.map((event, index) => (
                <li className="text-[0.6vw] text-green-400" key={index}>
                  <p>{event.type}:</p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <>
            {!viewCurrentScanned ? (
              <div className="flex flex-row mb-4">
                {/* <input
                  type="text"
                  id="name"
                  value={filterUrl}
                  onChange={(event) => setFilterUrl(event.target.value)}
                  placeholder="Search Url"
                  className="mt-1 block w-[20vw] bg-white border-[1px] rounded-md border-gray-300 shadow-inner focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 h-[3vh] text-[0.6vw] p-1"
                />
                <button
                  onClick={onSearchHandler}
                  className="h-[3vh] px-2 mt-1 rounded-[25px] hover:scale-[1.1] duration-200  active:scale-[0.8] font-semibold text-gray-500"
                >
                  <SearchIcon />
                </button> */}
              </div>
            ) : (
              <div className="m-auto text-center font-semibold text-red-500 underline">
                Scan Results:
              </div>
            )}
            {!viewCurrentScanned && <button
                  onClick={exportData}
                  className="h-[3vh] px-2  shadow-sm rounded-[25px] hover:shadow-xl duration-200  active:scale-[0.8] font-semibold text-gray-500 text-[0.8vw] border-b mb-5"
                >
                  <GetAppIcon/>Export To CSV
                </button>}
            <div className="h-[65vh] overflow-x-auto">
            
              <table className="w-[56vw] border border-white mx-auto table table-xs table-pin-rows table-pin-cols ">
                <thead className="text-[0.7vw]">
                  <tr className="">
                    <th className="cursor-pointer w-[10vw] border-b-[2px] border-white ">
                      URL
                    </th>
                    <th className="cursor-pointer w-[8vw] border-[2px] border-white">
                      Risk Score
                    </th>
                    <th
                      className="cursor-pointer w-[8vw] border-[2px] border-white"
                      onClick={() => handleSort("age")}
                    >
                      Adult
                    </th>
                    <th
                      className="cursor-pointer w-[8vw] border-[2px] border-white"
                      onClick={() => handleSort("age")}
                    >
                      Suspicious
                    </th>
                    <th className="cursor-pointer w-[8vw] border-[2px] border-white">
                      Phishing
                    </th>
                    <th className="cursor-pointer w-[8vw] border-[2px] border-white">
                      Malware
                    </th>
                    <th className="cursor-pointer py-3 px-4 rounded-tr-lg w-[8vw] border-[2px] border-white">
                      Spamming
                    </th>
                    <th className="cursor-pointer w-[8vw] border-[2px] border-white">
                      Parking
                    </th>
                    <th className="cursor-pointer w-[8vw] border-[2px] border-white">
                      LastUpdatedOn
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {scanResults.result.map((row, index) =>
                    row.success ? (
                      <tr
                        key={index}
                        className={`hover:bg-blue-100 text-[0.6vw]`}
                      >
                        <td className="py-3 px-4 text-[0.65vw] border border-white border-b-gray-200 text-[0.5vw] text-blue-700 w-[50vw] text-wrap break-all">
                          {row.url}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200">
                          {row.riskScore}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.adult ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.suspicious ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.phishing ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.malware ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.spamming ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-bold">
                          {row.parking ? (
                            <p className="text-red-500">{"True"}</p>
                          ) : (
                            <p className="text-green-500">{"False"}</p>
                          )}
                        </td>
                        <td className="py-3 px-4 border border-white border-b-gray-200 font-base text-gray-400">
                          {row.lastUpdatedOn}
                        </td>
                      </tr>
                    ) : (
                      <tr className="relative">
                        <td className="py-3 px-4 text-[0.65vw] border border-white border-b-gray-200 text-[0.5vw] text-blue-700 w-[50vw] text-wrap break-all">
                          {row.url}
                        </td>
                        <td className="py-3 px-4 border border-white font-base text-red-400 border-b-gray-200">
                          <p className="absolute">{row.errorMsg}</p>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {!scanInProgress?<div className="text-center">
          {viewCurrentScanned ? (
            <button
              onClick={onScanDone}
              className="text-purple-500 border-[1px] text-[0.7vw] h-[4vh] m-auto px-4 border-purple-300 mt-2 rounded-[5px] ml-2 hover:shadow-2xl duration-200 shadow-lg active:bg-purple-200 w-[10vw] font-semibold text-gray-500"
            >
                <TaskAltIcon/>
              Done
            </button>
          ) : (
            <div className="mx-auto w-fit mt-2">
              <Pagination
                count={totalPage}
                shape="rounded"
                onChange={(event, page) => {
                  onPaginationChangeHandler(page);
                }}
                page={currentPage}
              />
            </div>
          )}
        </div>:<></>}
      </div>
    </div>
  );
};

export default UrlScanner;
