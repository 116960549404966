import vonageLogo from "../../assets/vonage_logo.png"
export default function Loader() {
  return (
    <>

          <div class="loader7">
            <img src={vonageLogo}/>
      </div>
    </>
  );
}
