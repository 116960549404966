import Header from "./Header";
import * as React from 'react';
import vonageLogo from "../../assets/VonageLogo_Secondary.png"

export default function Home(props) {
  window.onload = function(){
    document.querySelector('.cont_principal').className= "cont_principal cont_error_active";  
      
    }
  return (
    <>
    <div class="cont_principal">
      <div className="
                    w-screen
                    h-screen
                    overflow-hidden">
      <Header/>
      <img
            src={vonageLogo}
            style={{
              width: 250,
              height: 200,
              margin: "auto",
              position: "absolute",
              left:0,
              right:0,
              top:0,
              bottom:0
            }}
          />
      <div className="bg-gray-100
                      backdrop-filter 
                      backdrop-blur
                      bg-opacity-60
                      p-5
                      mt-1 max-w-[95vw] h-5/6 m-auto rounded-2xl shadow-2xl overflow-hidden h-[90vh]">
        {props.children}

      </div>
    </div>
    <div class="cont_aura_1"></div>
    <div class="cont_aura_2"></div>
    </div>
    </>
  );
}