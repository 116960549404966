import vonageLogo from "../../assets/vonage_logo_loading.gif";
import { createContext, useState } from "react";

export const PageLoaderContext = createContext({
    showLoader:()=>{}
});

export default function PageLoader(props) {
  const [showLoader, setShowLoader] = useState(false);



  return (
    <>
      <PageLoaderContext.Provider value={{showLoader:setShowLoader}}>
        {showLoader && (
          <div className="w-full h-full  absolute z-10 backdrop-blur-sm m-auto backdrop-saturate-200">
            <img src={vonageLogo} className="m-auto"></img>
          </div>
        )}
        {props.children}
      </PageLoaderContext.Provider>
    </>
  );
}
