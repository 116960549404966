import * as React from "react";
import PieStat from "./PieStat";
import NewAlerts from "./NewAlerts";
import UserData from "./UserData";
import { PageLoaderContext } from "../../utils/PageLoader";
import { useContext } from "react";
import { AppDataContext } from "../../../App";
import LineStat from "./LineStat";
export default function Dashboard() {
  const authContext = useContext(PageLoaderContext);
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(0);
  authContext.showLoader(false);

  return (
    <div className="flex flex-row h-full">

          <div className="w-[29vw]">
            <UserData />
          </div>
          
          <div
            id="new-alerts"
            className="dasboard-container w-[29vw] bg-white flex flex-col rounded-3xl
                       overflow-true shadow-lg ml-10"
          >
            <NewAlerts />
          </div>
          <div
            id="new-alerts"
            className=" w-[35vw] flex flex-col rounded-3xl m-auto
                       overflow-true"
          >
            <PieStat />
            {/* <LineStat /> */}
            
            
          </div>


    </div>
  );
}
