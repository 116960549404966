import useHttp from "../../../hooks/useHttp";
import { getMonthStats } from "../../../service/Endpoints";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { MonthlyStatsResponse } from "../../../dto/ResponeDTO.ts";
import LineStat from "./LineStat";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Sector,
} from "recharts";
const stylePagination = {
  container: "flex flex-row h-4 m-auto justify-center mb-5",
  btn: "w-10  mt-2 shadow-xl shadow-fuchsia-200 rounded-lg text-fuchsia-700 text-xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-fuchsia-300",
};

let typeStatArchive = {};
let verdictStatArchive = {};

const TYPE_STAT_COLORS = [
  "#E58A9D",
  "#7E6AA6",
  "#BEBEBE",
  "#D48FD4",
  "#C0A8C0",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 7) * cos;
  const sy = cy + (outerRadius + 1) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        className="text-[0.55vw]"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name}-${value}`}</text>
      <text
        className="text-[0.55vw]"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const renderActiveShapeVerdict = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 40) * cos;
  const my = cy + (outerRadius + 100) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 40;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 10}
        fill={fill}
      />

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        className="text-[0.55vw] font-bold"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
      >{`${payload.name}`}</text>
      <text
        className="text-[0.55vw]"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieChartWith3D = ({ data, colors, data2 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const [activeIndexV, setActiveIndexV] = useState(0);
  const onPieEnterV = (_, index) => {
    setActiveIndexV(index);
  };
  return (
    <ResponsiveContainer width="100%" height="100%" className="relative" style={{marginLeft:"18px"}}>

      <PieChart>
        <Pie
          activeIndex={[0, 1, 2, 3, 4, 5, 6, 7]}
          activeShape={renderActiveShape}
          data={data}
          dataKey="value"
          nameKey="name"
          cx="45%"
          cy="38%"
          outerRadius="40%"
          innerRadius="20%"
          fill="#8884d8"
          paddingAngle={7}
          isAnimationActive={true}
          animationDuration={1500}
          animationEasing="ease-out"
          style={{ filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.4))" }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.fill ? entry.fill : colors[index % colors.length]}
              stroke="#000"
              strokeWidth={0.2}
            />
          ))}
        </Pie>
        <Pie
          data={data2}
          dataKey="value"
          cx="55%"
          cy="75%"
          outerRadius="30%"
          paddingAngle={7}
          innerRadius="10%"
          activeIndex={[0, 1, 2]}
          activeShape={renderActiveShapeVerdict}
        />

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function PieStat() {
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState } = useOktaAuth();
  const [activeTypeStat, setActiveTypeStat] = useState([]);
  const [activeVerdictStat, setActiveVerdictStat] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const getStats = useCallback((monthId) => {
    if (typeStatArchive[monthId] && verdictStatArchive[monthId]) {
      setActiveTypeStat(typeStatArchive[monthId]);
      setActiveVerdictStat(verdictStatArchive[monthId]);
    } else {
      let token = authState.accessToken.accessToken;
      callApi(
        { url: getMonthStats(monthId) },
        (responseData) => {
          const response = new MonthlyStatsResponse();
          response.applyData(responseData);
          let typeArray = [];
          let verdictArray = [];
          let count = 1;
          for (const key in response.typeStat) {
            let data = {};
            data["name"] = key;
            data["value"] = Number(response.typeStat[key]);
            typeArray.push(data);
            count++;
          }
          count = 1;
          for (const key in response.verdictStat) {
            let data = {};
            data["name"] = key;
            data["value"] = Number(response.verdictStat[key]);
            data["fill"] =
              key == "FRAUD"
                ? "#F8BFB0"
                : key == "NOT FRAUD"
                ? "#A8D5BA"
                : "#FCE8B2";
            verdictArray.push(data);
            count++;
          }
          typeStatArchive[monthId] = typeArray;
          verdictStatArchive[monthId] = verdictArray;
          setActiveTypeStat(typeStatArchive[monthId]);
          setActiveVerdictStat(verdictStatArchive[monthId]);
        },
        token
      );
    }
  });
  useEffect(() => {
    getStats(currentMonth + 1);
  }, []);

  const onMonthChange = (action) => {
    if (action === "PREV") {
      let newMonth = currentMonth - 1;
      if (currentMonth > -1) {
        setCurrentMonth(newMonth);
        getStats(newMonth + 1);
      }
    }
    if (action === "NEXT") {
      let newMonth = currentMonth + 1;
      if (currentMonth < 11) {
        setCurrentMonth(newMonth);
        getStats(newMonth + 1);
      }
    }
  };

  return (
    <>
      {activeTypeStat && activeVerdictStat && (
        <div className="w-[80%] h-[70vh] mx-auto overflow-visible relative  rounded-2xl ">
                    <div
            className={`absolute inset-0 rounded-2xl border-[1px]  bg-white blur-[1px] opacity-40 animate-pulse duration-500`}
          ></div>
          <div className="h-[40vh] m-auto">
            <PieChartWith3D
              data={activeTypeStat}
              data2={activeVerdictStat}
              colors={TYPE_STAT_COLORS}
            />
            <LineStat />
          </div>

        </div>
      )}
    </>
  );
}
