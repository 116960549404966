export class PageableFilter {
    limit:number;
    offset:number;
    sortBy:string;
    sortDir:string;
    filter:{};
    constructor() {
    }
    applyData(json:Object){
      Object.assign(this, json)
    }
}
export class PagableFilter {
  filters: Array<FieldFilter> = [];
  limit: number;
  offset: number;

  constructor() {
    this.filters = [];
    this.limit = -1;
    this.offset = -1;
  }
  addFilter(field: string, value: string, operator: string = "=") {
    const newFilter = new FieldFilter(field,value,operator);
    for (var i = 0; i < this.filters.length; i++) {
       if(field===this.filters[i].field){
        this.filters.splice(i,1);
        this.filters.push(newFilter)
        return;
       }
    }
    this.filters.push(newFilter);
  }
}
export class FieldFilter {
  operator: string;
  value: string;
  field: string;

  constructor(field: string="", value: string="", operator: string = "=") {
    this.field = field;
    this.value =value;
    this.operator = operator;
  }
}

export class DataTableHeader {
  public field: string;
  public headerName: string;
  public width: number;
  public renderCell: any = null;
  public type: string;
  public headerClassName: string;
  public flex: number;
  public valueOptions: any = [];
  public hide: boolean = true;
  public resizable: boolean = false;
  public sortable: boolean = true;

  seSortable(hide: boolean) {
    this.hide = hide;
    return this;
  }

  setResizable(hide: boolean) {
    this.hide = hide;
    return this;
  }
  setHide(hide: boolean) {
    this.hide = hide;
    return this;
  }
  setValueOption(valueOptions: any) {
    this.valueOptions = valueOptions;
    return this;
  }

  setFlex(flex: number) {
    this.flex = flex;
    return this;
  }

  setField(field: string) {
    this.field = field;
    return this;
  }
  setHeaderName(headerName: string) {
    this.headerName = headerName;
    return this;
  }
  setWidth(width: number) {
    this.width = width;
    return this;
  }
  setRenderCell(renderCell: any) {
    this.renderCell = renderCell;
    return this;
  }
  setType(type: string) {
    this.type = type;
    return this;
  }
  setHeaderClassName(headerClassName: string) {
    this.headerClassName = headerClassName;
    return this;
  }
}