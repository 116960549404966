// LoadingContext.js
import React, { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [message, setMessage] = useState("");
  const showNotification = (message = "", type = "generic") => {
    setNotificationType(type);
    setIsLoading(true);
    setMessage(message);
  };
  const hideNotification = () => setIsLoading(false);

  return (
    <NotificationContext.Provider
      value={{
        isLoading,
        showNotification,
        hideNotification,
        notificationType,
        message,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
