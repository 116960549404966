import "./notification.css";
import { useNotification } from "./NotificationContext";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  loader: {
    padding: "20px",
    borderRadius: "5px",
  },
};

export default function Notification() {
  const { isLoading, message, notificationType, hideNotification } =
    useNotification();
  const success = () => {
    return (
      <div class="wrapper green">
        <div class="header__wrapper">
          <div class="header">
            <div class="sign">
              <span></span>
            </div>
          </div>
        </div>
        <p
          className="mt-[20px] w-[100%] text-center text-[20px] text-green-500
        "
        >
          {message ? message : "Your request is successfull"}
        </p>

        <button onClick={() => hideNotification()} className="button">
          Continue
        </button>
      </div>
    );
  };

  const error = () => {
    return (
      <div class="wrapper red">
        <div class="header__wrapper">
          <div class="header">
            <div class="sign">
              <span></span>
            </div>
          </div>
        </div>
        <p
          className="mt-[20px] w-[100%] text-center text-[20px] text-red-500
        "
        >
          {message ? message : "Your request cannot be processed now"}
        </p>

        <button onClick={() => hideNotification()} className="button">
          Dismiss
        </button>
      </div>
    );
  };
  if (!isLoading) return null;
  return (
    <>
      <div style={styles.overlay} className="backdrop-blur-sm">
        {notificationType === "error" ? error() : success()}
      </div>
    </>
  );
}
