import * as React from "react";
import { useContext } from "react";
import { AppDataContext } from "../../../App.jsx";
import DataViewer from "../common/ActiveAlertViewer.jsx";
import { FieldFilter } from "../../../dto/ApplicationDTO.ts";


export default function MyAlert() {
  const appContext = useContext(AppDataContext);
  appContext.setSelectedTab(2);

  return (
    <>
        <DataViewer
          fixedFilter={[new FieldFilter("verdict","NOT DETERMINED"),new FieldFilter("userId",appContext.userData.userId)]}
          notFilter={[4,3,2]}
        />
      </>
  );
}
